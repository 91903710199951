export const STATUS = {
	[true]: 'page.categories.status.active',
	[false]: 'page.categories.status.inActive',
};

export const VISIBILITY = {
	[true]: 'page.categories.visibility.on',
	[false]: 'page.categories.visibility.off',
};

export const EXPAND = {
	[true]: 'page.categories.expand.on',
	[false]: 'page.categories.expand.off',
};
