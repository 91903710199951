<template>
	<div>
		<div class="d-flex">
			<div class="image-link">
				<a
					href="#"
					@click.prevent="handleClickPreviewIcon"
				>
					<div v-if="!icon" :class="{ 'image-placeholder': !icon }">
						<img src="/assets/images/product-placeholder.svg" :alt="placeholder" class="mb-1">
						{{ placeholder }}
					</div>
					<img
						v-else
						:src="icon.imageUrls.thumbnail"
						class="image-preview"
						alt=""
					>
				</a>
				<CButton
					v-if="icon"
					class="btn-delete-image"
					@click="handleDeletePreviewIcon"
				>
					<CIcon name="cil-trash" />
				</CButton>
			</div>
			<ul class="image-helper pl-4">
				<li>- Recommended resolution W{{ width }} x H{{ height }} px</li>
				<li>- Maximum upload size per file is 10MB</li>
				<li>- Supported PNG, JPG, JPEG, GIF</li>
			</ul>
		</div>


		<ModalMediaLibrary
			ref="modal-media-icon-library"
			type="single"
			@onSubmit="handleSubmitPreviewIcon"
		/>
	</div>
</template>

<script>
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';

export default {
	name: 'CategoryIcon',

	components: {
		ModalMediaLibrary,
	},

	props: {
		icon: {
			type: Object,
			default: null,
		},

		width: {
			type: String,
			default: '48',
		},

		height: {
			type: String,
			default: '54',
		},

		placeholder: {
			type: String,
			default: 'Add image',
		},
	},

	methods: {
		handleClickPreviewIcon() {
			this.$refs['modal-media-icon-library'].open();
		},

		handleDeletePreviewIcon() {
			this.$emit('update:icon', null);
		},

		handleSubmitPreviewIcon(files) {
			this.$emit('update:icon', files[0]);
		},
	},
};
</script>

<style lang="scss" scoped>
.image {
	// .image-placeholder
	&-placeholder {
		@include typo-helper-text;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: rem(78);
		height: rem(78);
		background-color: $color-gray-100;
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		color: $color-black-45;

		img {
			width: rem(32);
			height: rem(32);
		}
	}

	// .image-helper
	&-helper {
		list-style: none;
		margin: 0;

		li {
			@include typo-body-2;

			color: $color-black-45;
		}
	}

	// .image-link
	&-link {
		position: relative;
		display: block;

		a {
			&:hover {
				text-decoration: none;
			}
		}

		.btn-delete-image {
			position: absolute;
			right: rem(8);
			bottom: rem(8);
			width: rem(20);
			height: rem(20);
			box-shadow: none;
			padding: 0;
			border-radius: 2px;
			background-color: $color-black-45;

			svg {
				width: rem(12) !important;
				color: $color-white;
			}
		}
	}

	// .image-preview
	&-preview {
		width: rem(78);
		height: rem(78);
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		object-fit: contain;
	}
}

.is-invalid {
	.image-placeholder,
	.image-preview {
		border-color: $color-alert;
	}
}
</style>
