<template>
	<div>
		<CRow>
			<CCol md="7">
				<p class="typo-helper-text color-black-45 mb-0">
					Banner will be displayed on the top of category page. Recommended cover image size is 2560x670px.
				</p>
			</CCol>

			<CCol md="5" class="text-right">
				<CButton
					:disabled="isMaxValue"
					class="transparent"
					color="secondary"
					data-test-id="add-video"
					@click="handleAddItem"
				>
					<CIcon class="mr-2" name="cil-plus" /> Add Banner
				</CButton>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<BannerInputMedia
					v-for="(banner, index) in localBanners"
					ref="bannerInputMedia"
					:key="banner.key"
					:file="banner.file"
					:link="banner.link"
					:index="index"
					:label="`#${index + 1}`"
					class="banner-item"
					data-test-id="category-banner-item"
					placeholder="add banner link"
					@onInputImage="handleInputImage"
					@onDelete="handleDeleteItem"
					@onLinkChange="handleLinkChange"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import BannerInputMedia from '@/components/BannerInputMedia.vue';

export default {
	name: 'CategoryBanners',

	components: {
		BannerInputMedia,
	},

	props: {
		value: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			localBanners: [],
			maxValue: 10,
		};
	},

	computed: {
		isMaxValue() {
			return this.localBanners.length >= this.maxValue;
		},
	},

	created() {
		this.localBanners = this.value?.map((banner) => {
			return {
				...banner,
				key: this.generateKey(),
			};
		}) ?? [];
	},

	methods: {
		generateKey() {
			return uniqueId('media_key_');
		},
		handleAddItem() {
			if (!this.isMaxValue) {
				this.localBanners = [
					...this.localBanners,
					{
						file: null,
						link: null,
						key: this.generateKey(),
					},
				];

				this.emitInputValue();
			}
		},

		handleInputImage(file, index) {
			this.$set(this.localBanners, index, {
				...this.localBanners[index],
				file,
			});

			this.emitInputValue();
		},

		handleDeleteItem(value) {
			this.localBanners = this.localBanners.filter((_, index) => index !== value);

			this.emitInputValue();
		},

		handleLinkChange(link, index) {
			this.$set(this.localBanners, index, {
				...this.localBanners[index],
				link,
			});

			this.emitInputValue();
		},

		emitInputValue() {
			this.$emit('input', this.localBanners);
		},
	},
};
</script>

<style lang="scss" scoped>
	.banner-item {
		margin-top: rem(16);
	}
</style>

