<template>
	<div>
		<div class="position-relative">
			<div class="banner-image">
				<a
					href="#"
					@click.prevent="handleClickPreviewImage"
				>
					<div
						v-if="!previewImage"
						:class="{
							'banner-image-placeholder': !previewImage,
							'banner-image-required': $v.previewImage.$error
						}"
					>
						<img src="/assets/images/product-placeholder.svg" :alt="placeholder" class="mb-1">
						No banner
					</div>
					<img
						v-else
						:src="showPreviewImage(previewImage)"
						class="banner-image-preview"
						alt=""
					>
				</a>
				<CButton
					v-if="previewImage"
					class="btn-delete-image"
					@click="handleDeletePreviewImage"
				>
					<CIcon name="cil-trash" />
				</CButton>
			</div>

			<div class="w-100 input-container">
				<label>{{ label }}</label>

				<CInput
					v-model.trim="$v.localLink.$model"
					:is-valid="!$v.localLink.$error && null"
					:invalid-feedback="$t('global.error.url')"
					:placeholder="placeholder"
					class="w-100 mb-0"
					type="text"
					@input="handleLinkChange"
				/>

				<CButton
					class="btn-delete-item"
					@click="handleDeleteItem"
				>
					<CIcon name="cil-trash" />
				</CButton>
			</div>
		</div>

		<ModalMediaLibrary
			ref="media-library"
			type="single"
			@onSubmit="handleSubmitMedia"
		/>
	</div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';

import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';
import { getCategory1BannerMediumFromMediaObject } from '../assets/js/helpers';

export default {
	name: 'BannerInputMedia',

	components: {
		ModalMediaLibrary,
	},

	validations: {
		localLink: {
			required,
			url,
		},
		previewImage: {
			required,
		},
	},

	props: {
		link: {
			type: String,
			default: null,
		},
		file: {
			type: Object,
			default: () => ({}),
		},
		index: {
			type: Number,
			default: 0,
		},
		label: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			localLink: null,
			previewImage: null,
		};
	},

	created() {
		this.localLink = this.link;
		this.previewImage = this.file;
	},

	methods: {
		showPreviewImage(image) {
			return getCategory1BannerMediumFromMediaObject(image);
		},

		handleClickPreviewImage() {
			this.$refs['media-library'].open();
		},

		handleDeletePreviewImage() {
			this.previewImage = null;

			this.$emit('onInputImage', null, this.index);
		},

		handleDeleteItem() {
			this.$emit('onDelete', this.index);
		},

		handleSubmitMedia(images) {
			this.previewImage = {
				...images[0],
				urls: images[0].imageUrls,
			};

			this.$emit('onInputImage', this.previewImage, this.index);
		},

		handleLinkChange(value) {
			this.localLink = value;

			this.$emit('onLinkChange', this.localLink, this.index);
		},
	},
};
</script>

<style lang="scss" scoped>
	.input-container {
		margin-right: rem(54);
		display: flex;
		align-items: baseline;
		gap: rem(16);
	}

	.btn-delete-item {
		color: $color-black-25;
		padding: 0;
	}

	.banner-image {
		position: relative;
		display: block;
		margin-bottom: rem(16);
		height: rem(206);

		a {
			&:hover {
				text-decoration: none;
			}
		}

		.btn-delete-image {
			position: absolute;
			right: rem(8);
			bottom: rem(8);
			width: rem(20);
			height: rem(20);
			box-shadow: none;
			padding: 0;
			border-radius: 2px;
			background-color: $color-black-45;

			svg {
				width: rem(12) !important;
				color: $color-white;
			}
		}

		// .banner-image-placeholder
		&-placeholder {
			@include typo-helper-text;

			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 100%;
			height: rem(206);
			background-color: $color-gray-100;
			border: 1px solid $color-gray-300;
			border-radius: 2px;
			color: $color-black-45;

			img {
				width: rem(32);
				height: rem(32);
			}
		}

		// .banner-image-preview
		&-preview {
			width: 100%;
			height: 100%;
			border: 1px solid $color-gray-300;
			border-radius: 2px;
			object-fit: contain;
		}

		// .banner-image-preview
		&-required {
			border-color: $color-alert !important;
		}
	}
</style>
