<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<form v-else class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<CRow class="mb-5">
			<!-- Category level -->
			<CCol lg="12">
				<h4 class="mb-4">
					Category level
				</h4>
				<p class="label level-label">
					Creating category
				</p>
				<BaseButtonGroup
					v-model="level"
					:list="CATEGORY_LEVEL_OPTIONS"
					class="mb-4"
					@input="handleSelectedLevel"
				/>
				<CRow v-if="isParentSelectable" class="row-parent-category">
					<CCol md="6">
						<BaseDropDown
							v-model="$v.categoryParentLevelOne.$model"
							:options="categoryParentLevelOneOptions"
							:searchable="false"
							:is-valid="isValidCategoryParentLevelOne"
							:invalid-feedback="validatecategoryParentLevelOneMessage"
							label-drop-down="Under parent category (level 1)*"
							label="name"
							track-by="value"
							placeholder="Selection"
							class="select-custom"
							test-id="category-level-one-dropdown"
							@input="handleSelectedCategoryParentLevelOne"
						/>
					</CCol>
					<CCol v-if="isLevelThree" md="6">
						<BaseDropDown
							v-model="$v.categoryParentLevelTwo.$model"
							:options="categoryParentLevelTwoOptions"
							:searchable="false"
							:is-valid="isValidCategoryParentLevelTwo"
							:invalid-feedback="validatecategoryParentLevelTwoMessage"
							:disabled="!categoryParentLevelOne"
							label-drop-down="Under parent category (level 2)*"
							label="name"
							track-by="value"
							placeholder="Selection"
							class="select-custom"
							test-id="category-level-two-dropdown"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Category level -->

			<!-- General info -->
			<CCol lg="12">
				<h4 class="mb-4">
					General info
				</h4>
				<CRow>
					<CCol md="6">
						<CInput
							v-model.trim="$v.name.$model"
							data-id="category-name-en"
							:is-valid="isValidName"
							:invalid-feedback="validateNameMessage"
							label="Category name (EN)*"
							@input="clearError"
							@blur="handleAutoFillMetaTitleAndSlug"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="$v.nameTH.$model"
							data-id="category-name-th"
							:is-valid="isValidNameTH"
							:invalid-feedback="validateNameTHMessage"
							label="Category name (TH)*"
							@input="clearError"
							@blur="handleAutoFillMetaTitleTH"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.slug.$model"
							data-id="category-slug"
							:is-valid="isValidSlug"
							:invalid-feedback="validateSlugMessage"
							:description="slugHelperText"
							label="Slug"
							@input="clearError"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.customUrl.$model"
							:is-valid="!$v.customUrl.$error && null"
							:invalid-feedback="$t('global.error.url')"
							:description="'Allow only url. example: https://www.studio7thailand.com'"
							label="Custom URL"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="commission"
							data-id="category-commission"
							label="Commission (%)"
							@input="clearError"
						/>
					</CCol>
					<template v-if="isParentSelectable">
						<CCol xl="12" class="margin-bottom-32">
							<p class="label level-label">
								Icon image
							</p>
							<CategoryIcon :icon.sync="icon" />
						</CCol>
						<CCol
							xl="12"
							class="d-flex align-items-center margin-bottom-32"
						>
							<label class="toggle-label" for="category-is-new">
								New category
							</label>
							<CSwitch
								id="category-is-new"
								data-id="category-is-new"
								:checked="isNew"
								variant="3d"
								size="sm"
								class="switch-custom toggle-status"
								color="success"
								@update:checked="handleUpdateIsNew"
							/>
							<span>{{ isNewText }}</span>
						</CCol>
					</template>
					<CCol xl="12" class="d-flex align-items-center margin-bottom-32">
						<label class="toggle-label" for="category-status">
							Category status
						</label>
						<CSwitch
							id="category-status"
							data-id="category-status"
							:checked="status"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="handleUpdateStatus"
						/>
						<span>{{ statusText }}</span>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center margin-bottom-32">
						<label class="toggle-label" for="category-visibility">
							Storefront visibility
						</label>
						<CSwitch
							id="category-visibility"
							data-id="category-visibility"
							:checked="visibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateVisibility"
						/>
						<span>{{ visibilityText }}</span>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center">
						<label class="toggle-label" for="true-trade-in-visibility">
							TRUE / Trade in visibility
						</label>
						<CSwitch
							id="true-trade-in-visibility"
							data-id="true-trade-in-visibility"
							:checked="tradeInTruemoveVisibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateTradeInTruemoveVisibility"
						/>
						<span>{{ visibilityText }}</span>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end General info -->

			<!-- Compare -->
			<CCol lg="12">
				<h4 class="mb-4">
					Product compare
				</h4>
				<CRow>
					<CCol md="6">
						<CInput
							v-model.trim="compare"
							data-id="compare-text-en"
							label="Compare text (EN)"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="compareTH"
							data-id="compare-text-th"
							label="Compare text (TH)"
							@input="clearError"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.link.$model"
							class="margin-bottom-0-important"
							data-id="product-link"
							:is-valid="!$v.link.$error && null"
							:invalid-feedback="$t('global.error.url')"
							label="Compare Link"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Compare -->

			<!-- Content -->
			<CCol lg="12">
				<h4 class="mb-4">
					Contents
				</h4>
				<CRow>
					<CCol lg="12">
						<label>Category banners</label>
						<CategoryBanners
							ref="categoryBanners"
							v-model="banners"
						/>
					</CCol>
				</CRow>
				<CRow class="mt-4">
					<CCol lg="12">
						<label>Category description (top)</label>
						<div class="d-flex mb-2">
							<label class="toggle-label -is-expand-content" for="category-auto-expand">
								Auto expand content
							</label>
							<CSwitch
								id="category-auto-expand"
								:checked="isExpand"
								variant="3d"
								size="sm"
								class="switch-custom toggle-visibility"
								color="success"
								@update:checked="handleUpdateExpand"
							/>
							<span>{{ expandText }}</span>
						</div>
						<BaseRichTextEditor
							v-model="descriptionTop"
							name="descriptionTop"
							data-id="description-top"
						/>
					</CCol>
				</CRow>
				<CRow class="mt-2">
					<CCol lg="12">
						<label>Category description (bottom)</label>
						<BaseRichTextEditor
							v-model="descriptionBottom"
							name="descriptionBottom"
							data-id="description-bottom"
						/>
					</CCol>
				</CRow>
				<hr class="mt-4 mb-5">
			</CCol>
			<!-- end Content -->

			<!-- SEO -->
			<CCol lg="12">
				<h4 class="mb-4">
					SEO
				</h4>
				<CRow>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaTitle"
							data-id="meta-id-en"
							label="Meta title (EN)"
							rows="2"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaTitleTH"
							data-id="meta-id-th"
							label="Meta title (TH)"
							rows="2"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaKeyword"
							data-id="meta-keyword-en"
							label="Meta keywords (EN)"
							rows="3"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaKeywordTH"
							data-id="meta-keyword-th"
							label="Meta keywords (TH)"
							rows="3"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaDescription"
							data-id="meta-description-en"
							label="Meta description (EN)"
							rows="6"
							class="mb-0"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaDescriptionTH"
							data-id="meta-description-th"
							label="Meta description (TH)"
							rows="6"
							class="mb-0"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end SEO -->
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isCreating"
			content-class="main-wrapper"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'CategoryLists' })"
		/>
	</form>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { required, helpers, url } from 'vuelidate/lib/validators';
import CategoryIcon from '@/components/CategoryIcon.vue';
import CategoryBanners from '@/components/CategoryBanners.vue';
import { CATEGORY_LEVEL, CATEGORY_LEVEL_OPTIONS } from '../enums/categories';
import errorMessage from '../enums/errorMessage';
import { pathOr, scrollToTop } from '../assets/js/helpers';
import { transformedCategoryDropdownOption } from '../assets/js/transform/category';
import slug from '../regex/slug';
import {
	STATUS as mappingStatus,
	VISIBILITY as mappingVisibility,
	EXPAND as mappingExpand,
} from '../mapping/categories';

const slugify = require('slugify');

export default {
	name: 'CategoryCreatePage',

	components: {
		CategoryIcon,
		CategoryBanners,
	},

	validations() {
		// NOTE: Use code like this for use variable
		return {
			categoryParentLevelOne: {
				required: (value) => {
					if (this.isParentSelectable) {
						return required(value);
					}
					return true; // Pass when level 1
				},
			},
			categoryParentLevelTwo: {
				required: (value) => {
					if (this.isLevelThree) {
						return required(value);
					}
					return true; // Pass when level 1 & 2
				},
			},
			name: {
				required,
			},
			nameTH: {
				required,
			},
			slug: {
				required,
				format: helpers.regex('slug', slug),
				duplicate: () => !this.create.fieldErrors.slug,
			},
			customUrl: {
				url,
			},
			link: {
				url,
			},
		};
	},
	data() {
		return {
			// Level
			level: CATEGORY_LEVEL.ONE,
			CATEGORY_LEVEL_OPTIONS,
			categoryParentLevelOne: null,
			categoryParentLevelTwo: null,

			// General
			name: '',
			nameTH: '',
			slug: '',
			customUrl: null,
			commission: '',

			// Compare
			compare: '',
			compareTH: '',
			link: '',

			// Status
			isNew: false,
			status: true,
			visibility: false,
			tradeInTruemoveVisibility: false,
			isExpand: false,

			// More info
			descriptionTop: '',
			descriptionBottom: '',

			// SEO
			metaTitle: '',
			metaTitleTH: '',
			metaKeyword: '',
			metaKeywordTH: '',
			metaDescription: '',
			metaDescriptionTH: '',

			// Media
			banners: [],
			icon: null,

			// TODO: use real data
			bannerVisibility: false,
		};
	},
	computed: {
		...mapState('categories', {
			list: 'list',
			create: 'create',
		}),
		...mapGetters({
			categoryLists: 'categories/categoryLists',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		isCreating() {
			return this.create.isLoading;
		},

		// Categories dropdown options
		categoryParentLevelOneOptions() {
			return this.categoryLists.map(transformedCategoryDropdownOption);
		},
		categoryParentLevelTwoOptions() {
			if (this.categoryParentLevelOne) {
				const id = this.categoryParentLevelOne.value;
				const levelOneItem =
					this.categoryLists.find((category) => category.id === id) ||
					{};
				const list = levelOneItem.children || [];

				return list.map(transformedCategoryDropdownOption);
			}
			return [];
		},

		isParentSelectable() {
			return this.level !== CATEGORY_LEVEL.ONE;
		},
		isLevelThree() {
			return this.level === CATEGORY_LEVEL.THREE;
		},
		isValidCategoryParentLevelOne() {
			const isError = pathOr(false, [
				'$v',
				'categoryParentLevelOne',
				'$error',
			])(this);
			return isError ? false : null;
		},
		validatecategoryParentLevelOneMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'categoryParentLevelOne',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidCategoryParentLevelTwo() {
			const isError = pathOr(false, [
				'$v',
				'categoryParentLevelTwo',
				'$error',
			])(this);
			return isError ? false : null;
		},
		validatecategoryParentLevelTwoMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'categoryParentLevelTwo',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		// For submit
		categoryParentId() {
			if (this.isParentSelectable && this.isLevelThree) {
				return pathOr(null, ['categoryParentLevelTwo', 'value'])(this);
			} else if (this.isParentSelectable) {
				return pathOr(null, ['categoryParentLevelOne', 'value'])(this);
			}
			return null;
		},
		isValidName() {
			const isError = pathOr(false, ['$v', 'name', '$error'])(this);
			return isError ? false : null;
		},
		validateNameMessage() {
			const validateRequired = pathOr(false, ['$v', 'name', 'required'])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidNameTH() {
			const isError = pathOr(false, ['$v', 'nameTH', '$error'])(this);
			return isError ? false : null;
		},
		validateNameTHMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'nameTH',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidSlug() {
			const isError = pathOr(false, ['$v', 'slug', '$error'])(this);
			return isError ? false : null;
		},
		validateSlugMessage() {
			const validateRequired = pathOr(false, ['$v', 'slug', 'required'])(this);
			const validateFormat = pathOr(false, ['$v', 'slug', 'format'])(this);
			const validateDuplicate = pathOr(false, [
				'$v',
				'slug',
				'duplicate',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			} else if (!validateFormat) {
				return this.$t('page.categories.create.slugFormat');
			} else if (!validateDuplicate) {
				return this.$t('page.categories.create.duplicateSlug');
			}
			return null;
		},
		slugHelperText() {
			return this.isValidSlug !== false
				? this.$t('page.categories.create.slugFormat')
				: '';
		},
		isNewText() {
			const text = mappingStatus[this.isNew] || '';
			return this.$t(text);
		},
		statusText() {
			const text = mappingStatus[this.status] || '';
			return this.$t(text);
		},
		visibilityText() {
			const text = mappingVisibility[this.visibility] || '';
			return this.$t(text);
		},
		expandText() {
			const text = mappingExpand[this.isExpand] || '';
			return this.$t(text);
		},
	},
	created() {
		this.getCategories();
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			getCategories: 'categories/getCategories',
			postCategory: 'categories/postCategory',
			clearError: 'categories/clearCreateError',
		}),

		handleSelectedLevel(level) {
			this.clearError();

			// Should clear parent id value
			if (level === CATEGORY_LEVEL.ONE) {
				this.categoryParentLevelOne = null;
				this.categoryParentLevelTwo = null;
			} else if (level === CATEGORY_LEVEL.TWO) {
				this.categoryParentLevelTwo = null;
			}
		},
		handleSelectedCategoryParentLevelOne() {
			this.clearError();

			// Should clear parent id level 2 value
			this.categoryParentLevelTwo = null;
		},
		handleUpdateIsNew(value) {
			this.isNew = value;
			this.clearError();
		},
		handleUpdateStatus(value) {
			this.status = value;
			this.clearError();

			// When set status to false, should set visibility to false too
			if (value === false) {
				this.handleUpdateVisibility(false);
			}
		},
		handleUpdateVisibility(value) {
			this.visibility = value;
			this.clearError();
		},
		handleUpdateTradeInTruemoveVisibility(value) {
			this.tradeInTruemoveVisibility = value;
			this.clearError();
		},
		validateBanners() {
			const refs = this.$refs.categoryBanners.$refs.bannerInputMedia ?? [];
			refs.forEach((ref) => {
				ref.$v.$touch();
			});
		},
		checkValidBanners() {
			const refs = this.$refs.categoryBanners.$refs.bannerInputMedia ?? [];

			const isValid = refs
				.map((ref) => ref.$v.$invalid)
				.every((isInvalid) => isInvalid === false);

			return isValid;
		},
		async handleSubmit() {
			this.$v.$touch();
			this.validateBanners();

			if (!this.$v.$invalid && this.checkValidBanners()) {
				await this.postCategory({
					parent_id: this.categoryParentId,
					name_en: this.name,
					name_th: this.nameTH,
					slug: this.slug,
					custom_url: this.customUrl ? this.customUrl : null, // if is empty string change it to null
					is_new: this.isNew,
					is_active: this.status,
					is_visible: this.visibility,
					is_banner_visible: this.bannerVisibility,
					is_trade_in_truemove_visible: this.tradeInTruemoveVisibility,
					media: {
						banners: [],
						icons: this.icon ? [this.icon.id] : [],
					},
					banners: this.banners?.map((banner) => {
						return {
							file_id: banner?.file.id,
							link: banner.link,
						};
					}),
					description_top: this.descriptionTop,
					description_bottom: this.descriptionBottom,
					seo: {
						title_en: this.metaTitle,
						title_th: this.metaTitleTH,
						description_en: this.metaDescription,
						description_th: this.metaDescriptionTH,
						keyword_en: this.metaKeyword,
						keyword_th: this.metaKeywordTH,
					},
					commission: this.commission,
					banner_link: null,
					compare_link: this.link,
					compare_text_en: this.compare,
					compare_text_th: this.compareTH,
					is_description_top_expanded: this.isExpand,
				});

				// Redirect to category list page after created
				if (this.create.isSuccess) {
					this.$router.push({ name: 'CategoryLists' });

					// Show toast if api return any errors
				} else {
					let content = this.$t('global.errorMessage');
					if (this.create.fieldErrors.slug) {
						content = this.$t('page.categories.create.duplicateSlug');
					}

					this.showToast({
						content,
						header: this.$t('global.errorMessageTitleCreate'),
						type: 'danger',
					});
				}

				scrollToTop();
			}
		},
		handleAutoFillMetaTitleAndSlug() {
			if (!this.metaTitle) {
				this.metaTitle = this.name;
			}

			if (!this.slug) {
				this.slug = slugify(this.name, {
					lower: true,
					strict: true,
				});
				this.$v.slug.$touch();
			}
		},
		handleAutoFillMetaTitleTH() {
			if (!this.metaTitleTH) {
				this.metaTitleTH = this.nameTH;
			}
		},
		handleUpdateExpand(value) {
			this.isExpand = value;
			this.clearError();
		},
	},
};
</script>

<style lang="scss" scoped>
hr {
	margin: rem(40) 0;
}

.level-label {
	margin-bottom: rem(4);
}

.toggle-label {
	width: rem(200);
	margin: 0 rem(24) 0 0;

	&.-is-expand-content {
		width: auto !important;
	}
}

.toggle-status,
.toggle-visibility {
	margin-right: rem(12);
}

.btn-cancel,
.btn-submit {
	min-width: rem(132);
}

.row-parent-category {
	// margin left, right come from .row
	margin-top: rem(40);
	margin-bottom: rem(-40);
}

.margin-bottom-0 {
	// .margin-bottom-0
	&-important {
		margin-bottom: rem(0) !important;
	}
}

.margin-bottom-32 {
	margin-bottom: rem(32);
}

.form-group {
	margin-bottom: rem(40);
}
</style>
